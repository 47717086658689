import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import {DomSanitizer} from '@angular/platform-browser';
import {saveAs } from 'file-saver';
//import 'rxjs/Rx' ;
import { SharedService } from '@app/_services';
import { UserService } from '@app/_services';
import { User } from '@app/_models';


@Component({
  selector: 'app-global-profile',
  templateUrl: './global-profile.component.html',
  styleUrls: ['./global-profile.component.scss']
})
export class GlobalProfileComponent implements OnInit {
  user: User;
  card_url: string;
  fileUrl;
  fileName:string;
  classlogin = 'nologinclass';
  classloginhome = 'nologinclasshome';
  data;
  constructor(
    private router: Router,
    private SpinnerService: NgxSpinnerService,
    private sharedService: SharedService,
    private userService: UserService,
    private sanitizer:DomSanitizer
  ) { }

  ngOnInit(): void {
    this.SpinnerService.show();
    var serial = this.sharedService.serial;
    if (serial) {
      this.userService.check_card(serial).subscribe(data => {
        this.SpinnerService.hide();
        if(data){
          this.sharedService.serial = serial;
          if(data['card_paired'] == true){
            this.user = data['data'];
            this.card_url = data['card_url']
          }
          else{
            this.router.navigate(['register', serial]);
          }
        }
      },error => {
        this.SpinnerService.hide();
        this.router.navigate(['']);
      });
    }
    else{
      this.SpinnerService.hide();
      this.router.navigate(['']);
    }
  }

  goToLink(url: string, _blank: boolean, website:boolean=false, social=false, item:any=null){
    if(!social){
    if(website){
      if(url.startsWith('http') || url.startsWith('https'))
      {
        window.open(url, "_blank");
      }
      else{
        window.open('http://'+url, "_blank");
      }
    }
    else if(_blank){
    window.open(url, "_blank");
    }
    else{
      window.location.href = url;
    }
  }
  else{
    if(item.media=='youtube' || item.media=='linkedin'){
      url = item.profile_id;
    }
    else{
      url = item.profile_url + item.profile_id;
    }
    if(_blank){
      window.open(url, "_blank");
      }
      else{
        window.location.href = url;
      }
  }
  }
}

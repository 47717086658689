<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#458166" type="timer" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
  </ngx-spinner>
<div class="register">
    <div class="row">
        <div class="col-md-3 register-left">
            <img src="assets/img/logo.png" alt="" style="border-radius: 10px;"/>
            <h3>Welcome</h3>
            <p>Dinku Smart Business Cards…Smarter You!</p>
        </div>
        <div class="col-md-9 register-right">
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                    <h3 class="register-heading">Please Register for Dinku Cards</h3>
                    <div class="row register-form">
                        <div class="col-md-12">
                            <form [formGroup]="registrationForm" (ngSubmit)="onSubmit()">
                            <div class="form-group">
                                <input type="text"  formControlName="first_name"  [ngClass]="{ 'is-invalid': submitted && f.first_name.errors }" class="form-control" placeholder="First Name *"/>
                                <div *ngIf="submitted && f.first_name.errors" class="invalid-feedback">
                                    <div *ngIf="f.first_name.errors.required">First Name is required</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <input type="text" formControlName="last_name"  [ngClass]="{ 'is-invalid': submitted && f.last_name.errors }" class="form-control" placeholder="Last Name *" value="" />
                                <div *ngIf="submitted && f.last_name.errors" class="invalid-feedback">
                                    <div *ngIf="f.last_name.errors.required">Last Name is required</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <input type="email" formControlName="email"  [ngClass]="{ 'is-invalid': submitted && f.email.errors }" class="form-control" placeholder="Your Email *" value="" />
                                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                    <div *ngIf="f.email.errors.required">Email is required</div>
                                    <div *ngIf="f.email.errors.email">Not a valid email</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <input type="password" formControlName="password"  [ngClass]="{ 'is-invalid': submitted && f.password.errors }" class="form-control" placeholder="Password *" value="" />
                                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                    <div *ngIf="f.password.errors.required">Password is required</div>
                                    <div *ngIf="f.password.errors.minlength">Minimum length is {{f.password.errors.minlength.requiredLength}}</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <input type="password" formControlName="confirm_password"  [ngClass]="{ 'is-invalid': submitted && f.confirm_password.errors }" class="form-control"  placeholder="Confirm Password *" value="" />
                                <div *ngIf="submitted && f.confirm_password.errors" class="invalid-feedback">
                                    <div *ngIf="f.confirm_password.errors.required">Confirm Password is required</div>
                                    <div *ngIf="f.confirm_password.errors.confirmedValidator">Password and Confirm Password must be match.</div>
                                </div>
                            </div>
                            <button type="submit" class="btnRegister" >Register</button>
                            <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
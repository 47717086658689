import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DefaultComponent } from './default';
import { LoginComponent } from './login';
import { RegisterComponent } from './register';
import { HomeComponent } from './home';
import { AdminHomeComponent } from './admin-home';
import { EditProfileComponent } from './edit-profile';
import { AddMoreDataComponent } from './add-more-data';
import { GlobalProfileComponent } from './global-profile';
import { TermsComponent } from './terms';
import { PrivacyPolicyComponent } from './privacy-policy';
import { AccountConfirmationComponent } from './account-confirmation';
import { ChangePasswordComponent } from './change-password';
import { ForgotPasswordComponent } from './forgot-password';
import { ResetPasswordComponent } from './reset-password';
import { AuthGuard } from './_helpers';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: 'mycard',
    component: GlobalProfileComponent,
  },
  {
    path: 'mycard/:csr',
    component: DefaultComponent,
  },
  {
    path: 'register/:csr',
    component: DefaultComponent,
  },
  {
    path: 'register',
    component: RegisterComponent,
  },
  {
    path: 'account-confirmation/:token',
    component: AccountConfirmationComponent,
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard] },
  {
    path: 'admin-home',
    component: AdminHomeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'edit-profile',
    component: EditProfileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'add-more-data',
    component: AddMoreDataComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'terms',
    component: TermsComponent,
  },
  {
    path: 'privacy',
    component: PrivacyPolicyComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'reset-password/:token',
    component: ResetPasswordComponent,
  },
  // otherwise redirect to home
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

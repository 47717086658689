import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from "ngx-spinner";
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { MatDialogModule } from '@angular/material/dialog';
import { QRCodeModule } from 'angularx-qrcode';
import {MatTableModule} from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { DeviceDetectorModule } from 'ngx-device-detector';
import {MatExpansionModule} from '@angular/material/expansion';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { DefaultComponent } from './default/default.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { RegisterComponent } from './register/register.component';
import { GlobalProfileComponent } from './global-profile/global-profile.component';

import { SocialShareDialogComponent } from './social-share-dialog/social-share-dialog.component';
import { AdminHomeComponent } from './admin-home/admin-home.component';
import { QrViewDialogComponent } from './qr-view-dialog/qr-view-dialog.component';
import { AlertpopupComponent } from './alertpopup/alertpopup.component';
import { SafeUrlPipe } from './_services/safe-url.pipe';
import { TermsComponent } from './terms/terms.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { AccountConfirmationComponent } from './account-confirmation/account-confirmation.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { AddMoreDataComponent } from './add-more-data/add-more-data.component'


@NgModule({
  declarations: [
    AppComponent,
    DefaultComponent,
    LoginComponent,
    HomeComponent,
    EditProfileComponent,
    RegisterComponent,
    GlobalProfileComponent,
    SocialShareDialogComponent,
    AdminHomeComponent,
    QrViewDialogComponent,
    AlertpopupComponent,
    SafeUrlPipe,
    TermsComponent,
    PrivacyPolicyComponent,
    AccountConfirmationComponent,
    ChangePasswordComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    AddMoreDataComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    FontAwesomeModule,
    AppRoutingModule,
    MatDialogModule,
    QRCodeModule,
    MatTableModule,
    MatPaginatorModule,
    DeviceDetectorModule,
    MatExpansionModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
  entryComponents: [SocialShareDialogComponent]
})
export class AppModule {
  constructor() {
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-social-share-dialog',
  templateUrl: './social-share-dialog.component.html',
  styleUrls: ['./social-share-dialog.component.scss']
})
export class SocialShareDialogComponent implements OnInit {
  public myAngularxQrCode: string = null;

  constructor(
    public dialogRef: MatDialogRef<SocialShareDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    ) {}

  ngOnInit(): void {
    this.myAngularxQrCode = this.data['url_serial'];
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  
  goToLink(url: string, _blank: boolean){    
    if(_blank){
    window.open(url, "_blank");
    }
    else{
      window.location.href = url;
    }    
  }
}

<div class="" *ngIf="user">
  <div class="row flex-lg-nowrap whitedownarrow">
    <div class="col">
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <form [formGroup]="addMoreDataForm" (ngSubmit)="onSubmit()">
                <mat-accordion>
                  <mat-expansion-panel [expanded]="false" class="mat-elevation-z0">
                    <mat-expansion-panel-header class="card-header bg-info" [collapsedHeight]="'42px'" [expandedHeight]="'42px'">
                      <mat-panel-title style="font-weight: bold; color: white;">
                        Phone Number
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="table-responsive">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th>Type</th>
                            <th>Phone Number</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of user.additional_phone_data">
                            <td>{{ item.description }}</td>
                            <td>{{ item.value }}</td>
                            <td><i (click)="delete(item.id)" class="fa fa-trash" style="color: #606060;"></i></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div formArrayName="additional_phone_data">
                      <button type="button" (click)="addAdditionalPhoneData()" class="btn btn-primary">Add More</button>
                      <div *ngFor="let data of additional_phone_data().controls; let i=index" [formGroupName]="i">
                        <div class="row">
                          <div class="col">
                            <div class="form-group">
                              Type :
                              <select type="text" formControlName="description" class="form-control" [ngClass]="{ 'is-invalid': submitted && data.controls.description.errors }">
                                <option value="work">Work</option>
                                <option value="mobile">Mobile</option>
                                <option value="home">Home</option>
                              </select>
                              <div *ngIf="submitted && data.controls.description.errors" class="invalid-feedback">
                                <div *ngIf="data.controls.description.errors.required">Type is required</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <div class="form-group">
                              Phone Number:
                              <input type="text" formControlName="value" class="form-control" [ngClass]="{ 'is-invalid': submitted && data.controls.value.errors }">
                              <div *ngIf="submitted && data.controls.value.errors" class="invalid-feedback">
                                <div *ngIf="data.controls.value.errors.required">Number is required</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <div class="form-group">
                              <button (click)="removeAdditionalPhoneData(i)" class="btn btn-danger">Remove</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>

                <mat-accordion>
                  <mat-expansion-panel [expanded]="false" class="mat-elevation-z0">
                    <mat-expansion-panel-header class="card-header bg-info" [collapsedHeight]="'42px'" [expandedHeight]="'42px'">
                      <mat-panel-title style="font-weight: bold; color: white;">
                        Email
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="table-responsive">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th>Type</th>
                            <th>Email</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of user.additional_email_data">
                            <td>{{ item.description }}</td>
                            <td>{{ item.value }}</td>
                            <td><i (click)="delete(item.id)" class="fa fa-trash" style="color:#606060;"></i></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div formArrayName="additional_email_data">
                      <button type="button" (click)="addAdditionalEmailData()" class="btn btn-primary">Add More</button>
                      <div *ngFor="let data of additional_email_data().controls; let i=index" [formGroupName]="i">
                        <div class="row">
                          <div class="col">
                            <div class="form-group">
                              Type :
                              <select type="text" formControlName="description" class="form-control" [ngClass]="{ 'is-invalid': submitted && data.controls.description.errors }">
                                <option value="work">Work</option>
                                <option value="home">Personal</option>
                              </select>
                              <div *ngIf="submitted && data.controls.description.errors" class="invalid-feedback">
                                <div *ngIf="data.controls.description.errors.required">Type is required</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <div class="form-group">
                              Email:
                              <input type="text" formControlName="value" class="form-control" [ngClass]="{ 'is-invalid': submitted && data.controls.value.errors }">
                              <div *ngIf="submitted && data.controls.value.errors" class="invalid-feedback">
                                <div *ngIf="data.controls.value.errors.required">Email is required</div>
                                <div *ngIf="data.controls.value.errors.email">Not a valid email</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <div class="form-group">
                              <button (click)="removeAdditionalEmailData(i)" class="btn btn-danger">Remove</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>

                <mat-accordion>
                  <mat-expansion-panel [expanded]="false" class="mat-elevation-z0">
                    <mat-expansion-panel-header class="card-header bg-info" [collapsedHeight]="'42px'" [expandedHeight]="'42px'">
                      <mat-panel-title style="font-weight: bold; color: white;">
                        Presentation
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="table-responsive">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th>Description</th>
                            <th>URL</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of user.additional_presentation_data">
                            <td>{{ item.description }}</td>
                            <td>{{ item.value }}</td>
                            <td><i (click)="delete(item.id)" class="fa fa-trash" style="color: #606060;"></i></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div formArrayName="additional_presentation_data">
                      <button type="button" (click)="addAdditionalPresentationData()" class="btn btn-primary">Add More</button>
                      <div *ngFor="let data of additional_presentation_data().controls; let i=index" [formGroupName]="i">
                        <div class="row">
                          <div class="col">
                            <div class="form-group">
                              Description :
                              <input type="text" formControlName="description" class="form-control" [ngClass]="{ 'is-invalid': submitted && data.controls.description.errors }">
                              <div *ngIf="submitted && data.controls.description.errors" class="invalid-feedback">
                                <div *ngIf="data.controls.description.errors.required">Description is required</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <div class="form-group">
                              URL:
                              <input type="text" formControlName="value" class="form-control" [ngClass]="{ 'is-invalid': submitted && data.controls.value.errors }">
                              <div *ngIf="submitted && data.controls.value.errors" class="invalid-feedback">
                                <div *ngIf="data.controls.value.errors.required">URL is required</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <div class="form-group">
                              <button (click)="removeAdditionalPresentationData(i)" class="btn btn-danger">Remove</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>

                <mat-accordion>
                  <mat-expansion-panel [expanded]="false" class="mat-elevation-z0">
                    <mat-expansion-panel-header class="card-header bg-info" [collapsedHeight]="'42px'" [expandedHeight]="'42px'">
                      <mat-panel-title style="font-weight: bold; color: white;">
                        Video
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="table-responsive">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th>Description</th>
                            <th>URL</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of user.additional_video_data">
                            <td>{{ item.description }}</td>
                            <td>{{ item.value }}</td>
                            <td><i (click)="delete(item.id)" class="fa fa-trash" style="color: #606060;"></i></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div formArrayName="additional_video_data">
                      <button type="button" (click)="addAdditionalVideoData()" class="btn btn-primary">Add More</button>
                      <div *ngFor="let data of additional_video_data().controls; let i=index" [formGroupName]="i">
                        <div class="row">
                          <div class="col">
                            <div class="form-group">
                              Description :
                              <input type="text" formControlName="description" class="form-control" [ngClass]="{ 'is-invalid': submitted && data.controls.description.errors }">
                              <div *ngIf="submitted && data.controls.description.errors" class="invalid-feedback">
                                <div *ngIf="data.controls.description.errors.required">Description is required</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <div class="form-group">
                              URL:
                              <input type="text" formControlName="value" class="form-control" [ngClass]="{ 'is-invalid': submitted && data.controls.value.errors }">
                              <div *ngIf="submitted && data.controls.value.errors" class="invalid-feedback">
                                <div *ngIf="data.controls.value.errors.required">URL is required</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <div class="form-group">
                              <button (click)="removeAdditionalVideoData(i)" class="btn btn-danger">Remove</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
                <mat-accordion>
                  <mat-expansion-panel [expanded]="false" class="mat-elevation-z0">
                    <mat-expansion-panel-header class="card-header bg-info" [collapsedHeight]="'42px'" [expandedHeight]="'42px'">
                      <mat-panel-title style="font-weight: bold; color: white;">
                        Website/Blog
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="table-responsive">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th>Description</th>
                            <th>URL</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of user.additional_website_data">
                            <td>{{ item.description }}</td>
                            <td>{{ item.value }}</td>
                            <td><i (click)="delete(item.id)" class="fa fa-trash" style="color: #606060;"></i></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div formArrayName="additional_website_data">
                      <button type="button" (click)="addAdditionalWebsiteData()" class="btn btn-primary">Add More</button>
                      <div *ngFor="let data of additional_website_data().controls; let i=index" [formGroupName]="i">
                        <div class="row">
                          <div class="col">
                            <div class="form-group">
                              Description :
                              <input type="text" formControlName="description" class="form-control" [ngClass]="{ 'is-invalid': submitted && data.controls.description.errors }">
                              <div *ngIf="submitted && data.controls.description.errors" class="invalid-feedback">
                                <div *ngIf="data.controls.description.errors.required">Description is required</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <div class="form-group">
                              URL:
                              <input type="text" formControlName="value" class="form-control" [ngClass]="{ 'is-invalid': submitted && data.controls.value.errors }">
                              <div *ngIf="submitted && data.controls.value.errors" class="invalid-feedback">
                                <div *ngIf="data.controls.value.errors.required">URL is required</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <div class="form-group">
                              <button (click)="removeAdditionalWebsiteData(i)" class="btn btn-danger">Remove</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
                <div class="row">
                  <div class="col">
                    <button [disabled]="loading" class="btn btn-primary" style="margin-top: 10px; width: 100%; font-weight: bolder;" type="submit">
                      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>SAVE CHANGES
                    </button>
                    <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#458166" type="timer" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>
<div class="body">
    <div class="col-md-4 offset-md-4 mt-4 login" >
        <div class="card">
            <h4 class="card-header">Forgot Password</h4>
            <div class="card-body">
                <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <label for="email">Email</label>
                        <input type="text" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required">Email is required</div>
                            <div *ngIf="f.email.errors.email">Not a valid email</div>
                        </div>
                    </div>
                    <button [disabled]="loading" class="btn">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        Submit
                    </button>                
                    <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
                </form>
            </div>
        </div>
    </div>
    </div>

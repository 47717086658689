import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from '@app/_services';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { User } from '@app/_models';
import { AlertpopupComponent } from '@app/alertpopup';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-add-more-data',
  templateUrl: './add-more-data.component.html',
  styleUrls: ['./add-more-data.component.scss']
})
export class AddMoreDataComponent implements OnInit {
  addMoreDataForm: FormGroup;
  submitted = false;
  loading = false;
  error = '';
  user: User;
  isExtraSmall: Observable<BreakpointState> = this.breakpointObserver.observe(
    Breakpoints.XSmall
  );
  isMobile = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private SpinnerService: NgxSpinnerService,
    private userService: UserService,
    private readonly breakpointObserver: BreakpointObserver,
    public dialog: MatDialog,

  ) {
    this.addMoreDataForm = this.formBuilder.group({
      additional_phone_data: this.formBuilder.array([]),
      additional_email_data: this.formBuilder.array([]),
      additional_presentation_data: this.formBuilder.array([]),
      additional_video_data: this.formBuilder.array([]),
      additional_website_data: this.formBuilder.array([]),
    });
  }

  ngOnInit(): void {
    this.SpinnerService.show();
    this.userService.getAll().pipe(first()).subscribe(users => {
      this.SpinnerService.hide();
      this.user = users['data'];      
    });
  }

  //phone
  additional_phone_data(): FormArray {
    return this.addMoreDataForm.get("additional_phone_data") as FormArray
  }

  newAdditionalPhoneData(): FormGroup {
    return this.formBuilder.group({
      description: ['', [Validators.required]],
      value: ['', [Validators.required]]
    })
  }

  addAdditionalPhoneData() {
    this.additional_phone_data().push(this.newAdditionalPhoneData());
  }

  removeAdditionalPhoneData(i: number) {
    this.additional_phone_data().removeAt(i);
  }

  // Email
  additional_email_data(): FormArray {
    return this.addMoreDataForm.get("additional_email_data") as FormArray
  }

  newAdditionalEmailData(): FormGroup {
    return this.formBuilder.group({
      description: ['', [Validators.required]],
      value: ['', [Validators.required, Validators.email]]
    })
  }

  addAdditionalEmailData() {
    this.additional_email_data().push(this.newAdditionalEmailData());
  }

  removeAdditionalEmailData(i: number) {
    this.additional_email_data().removeAt(i);
  }

  // Presentation
  additional_presentation_data(): FormArray {
    return this.addMoreDataForm.get("additional_presentation_data") as FormArray
  }

  newAdditionalPresentationData(): FormGroup {
    return this.formBuilder.group({
      description: ['', [Validators.required]],
      value: ['', [Validators.required]]
    })
  }

  addAdditionalPresentationData() {
    this.additional_presentation_data().push(this.newAdditionalPresentationData());
  }

  removeAdditionalPresentationData(i: number) {
    this.additional_presentation_data().removeAt(i);
  }
  // Video
  additional_video_data(): FormArray {
    return this.addMoreDataForm.get("additional_video_data") as FormArray
  }

  newAdditionalVideoData(): FormGroup {
    return this.formBuilder.group({
      description: ['', [Validators.required]],
      value: ['', [Validators.required]]
    })
  }

  addAdditionalVideoData() {
    this.additional_video_data().push(this.newAdditionalVideoData());
  }

  removeAdditionalVideoData(i: number) {
    this.additional_video_data().removeAt(i);
  }


  // Website/Blog
  additional_website_data(): FormArray {
    return this.addMoreDataForm.get("additional_website_data") as FormArray
  }

  newAdditionalWebsiteData(): FormGroup {
    return this.formBuilder.group({
      description: ['', [Validators.required]],
      value: ['', [Validators.required]]
    })
  }

  addAdditionalWebsiteData() {
    this.additional_website_data().push(this.newAdditionalWebsiteData());
  }

  removeAdditionalWebsiteData(i: number) {
    this.additional_website_data().removeAt(i);
  }
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.addMoreDataForm.invalid) {
      return;
    }
    this.loading = true;    
    this.SpinnerService.show();
    this.userService.update_additional_data(this.addMoreDataForm.value)
      .pipe(first())
      .subscribe(
        data => {          
          this.SpinnerService.hide();
          this.alert("Saved Successfully!!!");          
        },
        error => {
          this.SpinnerService.hide();
          this.error = error;
          this.loading = false;
        });
  }

  alert(message:string):void{       
    const d = this.dialog.open(AlertpopupComponent, {
      panelClass: 'custom-dialog-container',      
      maxWidth: '95vw',
      data: {
        'message': message
      }
    });
    const smallDialogSubscription = this.isExtraSmall.subscribe(size => {
      if (size.matches) {        
        d.updateSize('100vw');
      } else {        
        d.updateSize('auto', '');
      }
    });
    d.afterClosed().subscribe(() => {
      smallDialogSubscription.unsubscribe();
        window.location.reload();  
    });
  }

  delete(data_id:string){            
    this.SpinnerService.show();
    this.userService.delete_additional_data(data_id)
      .pipe(first())
      .subscribe(
        data => {          
          this.SpinnerService.hide();                    
          this.alert("Deleted Successfully!!!");          
        },
        error => {
          this.SpinnerService.hide();
          this.error = error;                    
          this.alert("Error!!");
        });
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-alertpopup',
  templateUrl: './alertpopup.component.html',
  styleUrls: ['./alertpopup.component.scss']
})
export class AlertpopupComponent implements OnInit {
  message:any;
  constructor(
    public dialogRef: MatDialogRef<AlertpopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    ) { }

  ngOnInit(): void {
    this.message = this.data['message'];
  }
  
  onNoClick(): void {
    this.dialogRef.close();
  }

}

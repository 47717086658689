<nav class="navbar navbar-expand-md navbar-dark"  style="background-color: black;" *ngIf="currentUser">
  <a (click)="home()" class="navbar-brand logo mr-auto" style="margin-left: 100px;"></a>
  <button type="button" class="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarCollapse">
      <div class="navbar-nav ml-auto">
          <a *ngIf="currentUser.is_admin==false" class="nav-item nav-link menuitem active" data-toggle="collapse" data-target=".navbar-collapse.show" style="color: #adc7de;" (click)="gotodinku()"><i class="fas fa-home"></i> Home</a>
          <a *ngIf="currentUser.is_admin==false" class="nav-item nav-link menuitem" data-toggle="collapse" data-target=".navbar-collapse.show" style="color: #adc7de;" (click)="home()"><i class="fas fa-user"></i> Profile</a>
          <a *ngIf="currentUser.is_admin==false" class="nav-item nav-link menuitem" data-toggle="collapse" data-target=".navbar-collapse.show" style="color: #adc7de;" (click)="edit_profile()"><i class="fas fa-edit"></i> Edit Profile</a>
          <a *ngIf="currentUser.is_admin==false" class="nav-item nav-link menuitem" data-toggle="collapse" data-target=".navbar-collapse.show" style="color: #adc7de;" (click)="add_more_data()"><i class="fas fa-plus"></i> Add More Data</a>
          <!-- <a *ngIf="currentUser.is_admin==true" class="nav-item nav-link menuitem" data-toggle="collapse" data-target=".navbar-collapse.show" style="color: white;" (click)="admin_home()"> HOME </a> -->
          <a class="nav-item nav-link menuitem" data-toggle="collapse" data-target=".navbar-collapse.show" style="color: #adc7de;" (click)="change_password()"><i class="fas fa-key"></i> Change Password</a>
          <a class="nav-item nav-link menuitem" data-toggle="collapse" data-target=".navbar-collapse.show" style="color: #adc7de;" (click)="logout()"><i class="fas fa-sign-out-alt"></i> Logout</a>
      </div>
  </div>
</nav>


<!-- <div #services></div> -->
<router-outlet></router-outlet>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#458166" type="timer" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>
<div class="body">
    <div class="col-md-4 offset-md-4 mt-4 login">
        <div class="card">
            <h4 class="card-header">Reset Password</h4>
            <div class="card-body">
                <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <label>New Password</label>
                        <input type="password" class="form-control" formControlName="new_password"
                            [ngClass]="{ 'is-invalid': submitted && f.new_password.errors }" placeholder="">
                        <div *ngIf="submitted && f.new_password.errors" class="invalid-feedback">
                            <div *ngIf="f.new_password.errors.required">New password is required</div>
                            <div *ngIf="f.new_password.errors.minlength">Minimum length is
                                {{f.new_password.errors.minlength.requiredLength}}</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Confirm Password</label>
                        <input type="password" class="form-control" formControlName="confirm_password"
                            [ngClass]="{ 'is-invalid': submitted && f.confirm_password.errors }" placeholder="">
                        <div *ngIf="submitted && f.confirm_password.errors" class="invalid-feedback">
                            <div *ngIf="f.confirm_password.errors.required">Confirm Password is required
                            </div>
                            <div *ngIf="f.confirm_password.errors.confirmedValidator">Password
                                and Confirm Password must be match.</div>
                        </div>
                    </div>
                    <button [disabled]="loading" class="btn">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        Submit
                    </button>
                    <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
                </form>
            </div>
        </div>
    </div>
</div>
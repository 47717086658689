import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState
} from '@angular/cdk/layout';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Card } from '@app/_models';
import { UserService, ExcelService } from '@app/_services';
import { QrViewDialogComponent } from '@app/qr-view-dialog'
import { first } from 'rxjs/operators';
import { AlertpopupComponent } from '@app/alertpopup';

@Component({
  selector: 'app-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.scss']
})


export class AdminHomeComponent implements OnInit {
  loading = false;
  data_loaded = false;
  adminForm: FormGroup;
  tableColumns: string[] = ['serial', 'qr_code', 'company', 'paired_on', 'user', 'id'];
  cards = new MatTableDataSource<Card>();
  card_url: string;
  isExtraSmall: Observable<BreakpointState> = this.breakpointObserver.observe(
    Breakpoints.XSmall
  );

  data: any = [];
  submitted = false;
  error = '';
  success = '';
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  constructor(
    private userService: UserService,
    private SpinnerService: NgxSpinnerService,
    public dialog: MatDialog,
    private readonly breakpointObserver: BreakpointObserver,
    private excelService: ExcelService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.adminForm = this.formBuilder.group({
      no_of_cards: ['', [Validators.required, Validators.min(1), Validators.max(100)]],
      company: ['', [Validators.required]]
    });
    this.SpinnerService.show();
    this.userService.get_all_cards().subscribe(cards => {
      this.data_loaded = true;
      this.SpinnerService.hide();
      this.card_url = cards['card_url']
      this.cards.data = cards['data'];      
      this.cards.data.forEach(element => {
        if(element.paired_on == null){
          this.data.push({ 'My Card URL': this.card_url + element.url_serial, 'Company': element.company })
        }
      });
    },
      error => {
        this.data_loaded = false;
        this.SpinnerService.hide();
      });
  }

  ngAfterViewInit() {
    this.cards.paginator = this.paginator;
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.cards.filter = filterValue;
  }
  // convenience getter for easy access to form fields
  get f() { return this.adminForm.controls; }

  open_qr(url_serial: string) {
    const d = this.dialog.open(QrViewDialogComponent, {
      panelClass: 'custom-dialog-container',
      maxWidth: '95vw',
      data: {
        'url_serial':  this.card_url + url_serial
      }
    });
    const smallDialogSubscription = this.isExtraSmall.subscribe(size => {
      if (size.matches) {
        d.updateSize('100vw');
      } else {
        d.updateSize('auto', '');
      }
    });
    d.afterClosed().subscribe(() => {
      smallDialogSubscription.unsubscribe();
    });
  }

  exportAsXLSX(): void {
    this.excelService.exportAsExcelFile(this.data, 'Cards');
  }

  onSubmit() {    
    this.submitted = true;
    // stop here if form is invalid
    if (this.adminForm.invalid) {
      return;
    }

    this.loading = true;
    this.userService.generate_cards(this.f.no_of_cards.value, this.f.company.value).subscribe(data => {
      this.loading = false;
      this.success = data['message']
      setTimeout(() => { location.reload() }, 1500);
    },
      error => {
        this.error = error;
        this.loading = false;
      });
  }
  
  delete(data_id:string){            
    this.SpinnerService.show();
    this.userService.delete_card(data_id)
      .pipe(first())
      .subscribe(
        data => {          
          this.SpinnerService.hide();                    
          this.alert("Deleted Successfully!!!");          
        },
        error => {
          this.SpinnerService.hide();
          this.error = error;                    
          this.alert("Error!!");
        });
  }

  
  alert(message:string):void{       
    const d = this.dialog.open(AlertpopupComponent, {
      panelClass: 'custom-dialog-container',      
      maxWidth: '95vw',
      data: {
        'message': message
      }
    });
    const smallDialogSubscription = this.isExtraSmall.subscribe(size => {
      if (size.matches) {        
        d.updateSize('100vw');
      } else {        
        d.updateSize('auto', '');
      }
    });
    d.afterClosed().subscribe(() => {
      smallDialogSubscription.unsubscribe();
        window.location.reload();  
    });
  }
}

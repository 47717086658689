<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#458166" type="timer" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>
<div class="" *ngIf="user">
  <div class="row flex-lg-nowrap" style="margin:0px !important">
    <div class="col">
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <div class="e-profile">
                <div class="row">
                  <div class="col-12 col-sm-auto mb-3">
                    <div class="mx-auto" style="width: 140px;">
                      <div class="" style="height: 140px; width: 140px; background-color: rgb(238, 229, 229); border-radius:50%;">
                        <span *ngIf="previewUrl==null" style="color: rgb(166, 168, 170); font: bold 8pt Arial;border-radius:50%;"></span>
                        <img *ngIf="previewUrl" [src]="previewUrl" height="140" width="140" style="border-radius:50%;" />
                      </div>
                    </div>
                  </div>
                  <div class="col d-flex flex-column flex-sm-row justify-content-between mb-3">
                    <div class="text-center text-sm-left mb-2 mb-sm-0">
                      <h4 style="font-family:LATO_BLACK" class="pt-sm-2 pb-1 mb-0 text-nowrap">{{ user.personal_details.first_name | uppercase }}
                        {{ user.personal_details.last_name | uppercase }}</h4>
                      <p class="mb-0">{{ user.personal_details.user_name }}</p>
                      <p class="mb-0">{{ user.personal_details.email }}</p>
                      <div><small>Last login {{ user.personal_details.last_login }}</small></div>
                      <div class="mt-2">
                        <input type="file" accept="image/*" #file style="display: none;" (change)="fileProgress($event)">
                        <button class="btn btn-primary" style="font-size: 13px;" type="button" (click)="file.click()">
                          <i class="fa fa-fw fa-camera"></i>
                          <span> CHANGE PHOTO </span>
                        </button>
                      </div>
                    </div>
                    <div class="text-center text-sm-right" style="padding-top:10px">
                      <span class="badge badge-success">{{ user.personal_details.role }}</span>
                      <div><small>Joined {{ user.personal_details.registered_on }}</small></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <form class="form" [formGroup]="editProfileForm" (ngSubmit)="onSubmit()" novalidate="">
              <div class="card">
                <div class="card-header bg-info text-center" style="font-weight: bold;
                      color: white; margin-bottom:10px; font-size:14px;
                      border-radius: 0px;padding-top:8px;padding-bottom:8px">PERSONAL INFORMATION</div>
                <div formGroupName="personal_details">
                  <div class="card-body">
                    <div class="card-text">
                      <div class="row">
                        <div class="col">
                          <div class="row">
                            <div class="col-md-6 col-sm-12">
                              <div class="form-group">
                                <label>FIRST NAME</label>
                                <input type="text" class="form-control" formControlName="first_name" [ngClass]="{ 'is-invalid': submitted && p.first_name.errors }" placeholder="FIRST NAME">
                                <div *ngIf="submitted && p.first_name.errors" class="invalid-feedback">
                                  <div *ngIf="p.first_name.errors.required">FIRST NAME IS REQUIRED</div>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6 col-sm-12">
                              <div class="form-group">
                                <label>LAST NAME</label>
                                <input type="text" class="form-control" formControlName="last_name" [ngClass]="{ 'is-invalid': submitted && p.last_name.errors }" placeholder="LAST NAME">
                                <div *ngIf="submitted && p.last_name.errors" class="invalid-feedback">
                                  <div *ngIf="p.last_name.errors.required">LAST NAME IS REQUIRES</div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <!-- <div class="row">
                                  <div class="col">
                                    <div class="form-group">
                                      <label>Username</label>
                                      <input type="text" class="form-control" formControlName="user_name"
                                        [ngClass]="{ 'is-invalid': submitted && p.user_name.errors }"
                                        placeholder="user_name">
                                      <div *ngIf="submitted && p.user_name.errors" class="invalid-feedback">
                                        <div *ngIf="p.user_name.errors.required">User Name is required</div>
                                      </div>
                                    </div>
                                  </div>
                                </div> -->
                          <div class="row">
                            <div class="col-md-6 col-sm-12">
                              <div class="form-group">
                                <label>ROLE</label>
                                <input type="text" class="form-control" formControlName="role" [ngClass]="{ 'is-invalid': submitted && p.role.errors }" placeholder="ROLE">
                                <div *ngIf="submitted && p.role.errors" class="invalid-feedback">
                                  <div *ngIf="p.role.errors.required">ROLE IS REQUIRED</div>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6 col-sm-12">
                              <div class="form-group">
                                <label>COMPANY</label>
                                <input type="text" class="form-control" formControlName="company" [ngClass]="{ 'is-invalid': submitted && p.company.errors }" placeholder="COMPANY">
                                <div *ngIf="submitted && p.company.errors" class="invalid-feedback">
                                  <div *ngIf="p.company.errors.required">COMPANY IS REQUIRED</div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-md-6 col-sm-12">
                              <div class="form-group">
                                <label>PHONE NUMBER</label>
                                <input type="text" class="form-control" formControlName="phone" [ngClass]="{ 'is-invalid': submitted && p.phone.errors }" placeholder="+(0) 000 000">
                                <div *ngIf="submitted && p.phone.errors" class="invalid-feedback">
                                  <div *ngIf="p.phone.errors.required">PHONE IS REQUIRED</div>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6 col-sm-12">
                              <div class="form-group">
                                <label>WEBSITE</label>
                                <input type="text" class="form-control" formControlName="website" [ngClass]="{ 'is-invalid': submitted && p.website.errors }" placeholder="WWW.EXAMPLE.COM">
                                <div *ngIf="submitted && p.website.errors" class="invalid-feedback">
                                  <div *ngIf="p.website.errors.required">WEBSITE IS REQUIRED</div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col">
                              <div class="form-group">
                                <label>ADDRESS</label>
                                <textarea style="padding:10px" type="text" class="form-control" formControlName="address" [ngClass]="{ 'is-invalid': submitted && p.address.errors }" rows="1" placeholder="MY ADDRESS"></textarea>
                                <div *ngIf="submitted && p.address.errors" class="invalid-feedback">
                                  <div *ngIf="p.address.errors.required">ADDRESS IS REQUIRED</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header bg-info text-center" style="font-weight: bold; color: white; margin-bottom: 10px;
                      font-size:14px;
                      border-radius: 0px;padding-top:8px;padding-bottom:8px">SOCIAL MEDIA</div>
                <div *ngIf="!isMobile">
                  <div formGroupName="social_media_data">
                    <div class="card-body">
                      <div class="card-text">
                        <div class="row">
                          <div class="col">
                            <div class="row">
                              <div class="col-md-6 col-sm-12">
                                <label>FACEBOOK</label>
                                <div class="input-group mb-3">
                                  <div class="input-group-prepend">
                                    <span class="input-group-text facebook">
                                      <i class="fab fa-facebook-f" style="color:white;" title="facebook"></i>
                                    </span>
                                  </div>
                                  <input style="border:none" class="form-control" type="text" formControlName="facebook" placeholder="FACEBOOK_ID">
                                </div>
                              </div>
                              <div class="col-md-6 col-sm-12">
                                <label>LINKEDIN</label>
                                <div class="input-group mb-3">
                                  <div class="input-group-prepend">
                                    <span class="input-group-text linkedin">
                                      <i class="fab fa-linkedin" style="color:white;" title="linkedin"></i>
                                    </span>
                                  </div>
                                  <input class="form-control" type="text" formControlName="linkedin" placeholder="LINKEDIN_ID">
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6 col-sm-12">
                                <label>TWITTER</label>
                                <div class="input-group mb-3">
                                  <div class="input-group-prepend">
                                    <span class="input-group-text twitter">
                                      <i class="fab fa-twitter" style="color:white;" title="twitter"></i>
                                    </span>
                                  </div>
                                  <input class="form-control" type="text" formControlName="twitter" placeholder="TWITTER_ID">
                                </div>
                              </div>
                              <div class="col-md-6 col-sm-12">
                                <label>INSTAGRAM</label>
                                <div class="input-group mb-3">
                                  <div class="input-group-prepend">
                                    <span class="input-group-text instagram">
                                      <i class="fab fa-instagram" style="color:white;" title="instagram"></i>
                                    </span>
                                  </div>
                                  <input class="form-control" type="text" formControlName="instagram" placeholder="INSTAGRAM_ID">
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col">
                                <label>YOUTUBE</label>
                                <div class="input-group mb-3">
                                  <div class="input-group-prepend">
                                    <span class="input-group-text youtube">
                                      <i class="fab fa-youtube" style="color:white;" title="youtube"></i>
                                    </span>
                                  </div>
                                  <input class="form-control" type="text" formControlName="youtube" placeholder="https:/www.youtube.com/channel/xxxxxxxxxxxxxxxx">
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="isMobile">
                  <div formGroupName="social_media_data" class="social_media_data">
                    <div class="card-body">
                      <div class="card-text">
                        <div class="row">
                          <div class="col">
                            <div class="row">
                              <div class="col-md-6 col-sm-12">
                                <div class="form-group">
                                  <label>FACEBOOK</label>
                                  <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text facebook">
                                        <i class="fab fa-facebook-f" style="color:white;" title="facebook"></i>
                                      </span>
                                    </div>
                                    <input class="form-control" type="text" formControlName="facebook" placeholder="FACEBOOK_ID">
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-6 col-sm-12">
                                <div class="form-group">
                                  <label>LINKEDIN</label>
                                  <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text linkedin">
                                        <i class="fab fa-linkedin" style="color:white;" title="linkedin"></i>
                                      </span>
                                    </div>
                                    <input class="form-control" type="text" formControlName="linkedin" placeholder="LINKEDIN_ID">
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col-md-6 col-sm-12">
                                <div class="form-group">
                                  <label>TWITTER</label>
                                  <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text twitter">
                                        <i class="fab fa-twitter" style="color:white;" title="twitter"></i>
                                      </span>
                                    </div>
                                    <input class="form-control" type="text" formControlName="twitter" placeholder="TWITTER_ID">
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-6 col-sm-12">
                                <div class="form-group">
                                  <label>INSTAGRAM</label>
                                  <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text instagram">
                                        <i class="fab fa-instagram" style="color:white;" title="instagram"></i>
                                      </span>
                                    </div>
                                    <input class="form-control" type="text" formControlName="instagram" placeholder="INSTAGRAM_ID">
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row">

                            </div>
                            <div class="row">
                              <div class="col">
                                <label>YOUTUBE</label>
                                <div class="input-group mb-3">
                                  <div class="input-group-prepend">
                                    <span class="input-group-text youtube">
                                      <i class="fab fa-youtube" style="color:white;" title="youtube"></i>
                                    </span>
                                  </div>
                                  <input class="form-control" type="text" formControlName="youtube" placeholder="https:/www.youtube.com/channel/xxxxxxxxxxxxxxxx">
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col d-flex justify-content-center" style="margin-bottom: 25px;margin-top:15px">
                  <button class="btn btn-primary" style="width: 100%;
                        font-weight: bolder; border-radius:0px" type="submit">SAVE CHANGES
                  </button>
                  <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
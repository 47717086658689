import { Component, OnInit, Inject  } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-qr-view-dialog',
  templateUrl: './qr-view-dialog.component.html',
  styleUrls: ['./qr-view-dialog.component.scss']
})
export class QrViewDialogComponent implements OnInit {
  myAngularxQrCode:string;
  constructor(
    public dialogRef: MatDialogRef<QrViewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {    
    this.myAngularxQrCode = this.data['url_serial'];

  }

  
  onNoClick(): void {
    this.dialogRef.close();
  }

}

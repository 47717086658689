import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '@app/_services';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-account-confirmation',
  templateUrl: './account-confirmation.component.html',
  styleUrls: ['./account-confirmation.component.scss']
})
export class AccountConfirmationComponent implements OnInit {
  show_message = false;
  already_activated = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private SpinnerService: NgxSpinnerService,
    private userService: UserService,
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.SpinnerService.show();
      var token = params.get('token');            
      if (token !== null) {
        this.userService.confirm_account(token).subscribe(data => {
          this.SpinnerService.hide();          
          if(data){                          
            if(data['already_activated'] == true){              
              this.show_message = true;
              this.already_activated = true;
            }
            else{
              this.show_message = true;
              this.already_activated = false;
            }
          }
        },error => {
          this.SpinnerService.hide();
          //this.router.navigate(['']);
        });
      }
      else{
        this.SpinnerService.hide();        
      }
    });
  }

}

import { Component } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

import { AuthenticationService } from './_services';
import { User } from './_models';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  currentUser: User;
  title = 'dinkucard-frontend';
  showMenu: boolean;
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    this.authenticationService.currentUser.subscribe(
      (x) => (this.currentUser = x)
    );
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        this.showMenu = event.url !== '/mycard';
      }
    });
  }

  logout() {
    this.authenticationService.logout();
    this.gotodinku();
  }

  edit_profile() {
    this.router.navigate(['/edit-profile']);
  }

  add_more_data() {
    this.router.navigate(['/add-more-data']);
  }

  change_password() {
    this.router.navigate(['/change-password']);
  }

  home() {
    if (this.currentUser.is_admin) {
      this.router.navigate(['/admin-home']);
    } else {
      this.router.navigate(['/home']);
    }
  }

  header() {
    this.router.navigate(['']);
  }

  about() {
    this.router.navigate(['/']).then(() => {
      window.location.hash = 'about';
    });
  }

  services() {
    this.router.navigate(['/']).then(() => {
      window.location.hash = 'services';
    });
  }

  portfolio() {
    this.router.navigate(['/']).then(() => {
      window.location.hash = 'portfolio';
    });
  }

  team() {
    this.router.navigate(['/']).then(() => {
      window.location.hash = 'team';
    });
  }

  contact() {
    this.router.navigate(['/']).then(() => {
      window.location.hash = 'contact';
    });
  }

  login() {
    this.router.navigate(['/login']);
  }
  gotodinku() {
    window.location.href = 'https://dinku.me/cards/';
  }

  scrollToElement(element: string): void {
    var $element = document.getElementById(element);
    if ($element != null) {
      if (element != 'hero') {
        $element.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'start',
        });
      } else {
        window.scroll(0, 0);
      }
    } else {
      this.router.navigate(['/']).then(() => {
        var $element = document.getElementById(element);
        if (element != 'hero') {
          $element.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'start',
          });
        } else {
          window.scroll(0, 0);
        }
      });
    }
  }
}

<div class="body">
    <div *ngIf="show_message" class="col-md-6 offset-md-3 mt-5 login">
        <div class="card">
            <h4  *ngIf="!already_activated" class="card-header">Account activated</h4>
            <h4  *ngIf="already_activated" class="card-header">Account already activated</h4>
            <div *ngIf="!already_activated" class="card-body">
                <p >Awsome! Your account has been activated.</p>
                <p>Welcome again to Dinku Cards. Please login and complete your profile.</p>
            </div>
            <div *ngIf="already_activated" class="card-body">
                <p >Your account is already activated.</p>
                <p>Please login and complete your profile.</p>
            </div>
        </div>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState
} from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';

import { User } from '@app/_models';
import { UserService } from '@app/_services';
import { AlertpopupComponent } from '@app/alertpopup'
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {
  editProfileForm: FormGroup;  
  photo: File = null;
  previewUrl: any = null;
  submitted = false;
  loading = false;
  error = '';
  user: User;
  isExtraSmall: Observable<BreakpointState> = this.breakpointObserver.observe(
    Breakpoints.XSmall
  );
  isMobile = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private SpinnerService: NgxSpinnerService,
    private userService: UserService,
    private readonly breakpointObserver: BreakpointObserver,
    public dialog: MatDialog,
    private deviceService: DeviceDetectorService
    ) {this.epicFunction(); }

  ngOnInit(): void {
    this.SpinnerService.show();


    this.loading = true;

    this.userService.getAll().pipe(first()).subscribe(users => {
      this.SpinnerService.hide();
      this.user = users['data'];      
      this.editProfileForm = new FormGroup({
        personal_details: new FormGroup({        
        user_name: new FormControl(this.user.personal_details.user_name),
        first_name: new FormControl(this.user.personal_details.first_name, Validators.required),
        last_name: new FormControl(this.user.personal_details.last_name, Validators.required),
        role: new FormControl(this.user.personal_details.role),
        company: new FormControl(this.user.personal_details.company),   
        email: new FormControl(this.user.personal_details.email, [Validators.required, Validators.email]) ,     
        phone: new FormControl(this.user.personal_details.phone),   
        website: new FormControl(this.user.personal_details.website),   
        address: new FormControl(this.user.personal_details.address),   
      }),
      social_media_data: new FormGroup({
        facebook: new FormControl(this.user.social_media_edit_data.facebook.profile_id),
        linkedin: new FormControl(this.user.social_media_edit_data.linkedin.profile_id),
        twitter: new FormControl(this.user.social_media_edit_data.twitter.profile_id),
        instagram: new FormControl(this.user.social_media_edit_data.instagram.profile_id),
        youtube: new FormControl(this.user.social_media_edit_data.youtube.profile_id),
      })      
    });
    this.previewUrl = this.user.personal_details.profile_photo;
    },
    error => {
      this.loading = false;
      this.SpinnerService.hide();
      
    });
  }

  fileProgress(fileInput: any) {
    this.photo = <File>fileInput.target.files[0];
    this.preview();
  }

  preview() {
    // Show preview 
    var mimeType = this.photo.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(this.photo);
    reader.onload = (_event) => {
      this.previewUrl = reader.result;
    }
  }

  get personal_details(): any {
    return this.editProfileForm.get('personal_details');
  }

  get social_media_data(): any {
    return this.editProfileForm.get('social_media_data');
  }

  // convenience getter for easy access to form fields
  get f() { return this.editProfileForm.controls; }
  get p(){ return this.personal_details.controls;}
  get s() { return this.social_media_data.controls; }

  onSubmit() {    
    this.submitted = true;

    // stop here if form is invalid
    if (this.editProfileForm.invalid) {      
      return;
    }          
    this.loading = true;
    this.SpinnerService.show();
    this.userService.update(this.photo, this.editProfileForm.value)
      .pipe(first())
      .subscribe(
        data => {          
          this.SpinnerService.hide();
          this.alert("Saved Successfully!!!");          
        },
        error => {
          this.SpinnerService.hide();
          this.error = error;
          this.loading = false;
        });
  }

  alert(message:string):void{       
    const d = this.dialog.open(AlertpopupComponent, {
      panelClass: 'custom-dialog-container',      
      maxWidth: '95vw',
      data: {
        'message': message
      }
    });
    const smallDialogSubscription = this.isExtraSmall.subscribe(size => {
      if (size.matches) {        
        d.updateSize('100vw');
      } else {        
        d.updateSize('auto', '');
      }
    });
    d.afterClosed().subscribe(() => {
      smallDialogSubscription.unsubscribe();
      this.router.navigate(['../home'], { relativeTo: this.route });
    });
  }

  
  epicFunction() {    
    this.isMobile = this.deviceService.isMobile();         
  }
}

<div class="container">
    <div class="section-title">
        <br>
        <br>
        <h2 data-aos="fade-in">TERMS AND CONDITIONS</h2>
    </div>
    <p>By using Dinku Cards website & gadgets, you agree to be bound by these terms & conditions.</p>
    <p>• Access to Dinku Cards services will be provided to only registered customers.</p>
    <p>• All information provided to Dinku Cards for registration should be truthful, any false information will proceed to cancel or suspend the account.</p>
    <p>• Dinku Cards portal and gadgets should be handled by the authorized persons only.</p>
    <p>• Do not create Dinku Cards account for someone else without their permission.</p>
    <p>• Cookies: Dinku Cards uses cookies to enable us to retrieve user defaults for each site. Cookies are used in some areas of site to enable the functionality of that area.</p>
    <p>• Dinku Cards will not be responsible for any physical damage to Dinku Cards gadgets after delivery to Client.</p>
    <p>• Responsibility for management of the profile of a user account shall be solely that of the Client.</p>
    <p>• Do not share login credentials to unauthorised persons.</p>
    <p>• Dinku-Cards will not be responsible for any 3rd party companies using cookies for tracking purpose.</p>
    <p>• Do not upload any harmful link, site, virus or any code to your profile. We will do our best to keep your data safe. Accordingly, we will not be liable for any loss occasioned by unethical or unacceptable activities by a user with our gadgets or platform.</p>
    <p>• Dinku Cards has no control over the content or other functionalities of the URL, Website or WebPages that you upload to your profile and will not be liable for their content or characteristics.</p>
    <p>• For the avoidance of doubt, Dinku-Cards is not responsible for any content or information that a user uploads and transfers.</p>
    <p>• We are not monitoring or controlling Dinku Cards user’s actions. So Dinku-Cards will not be responsible for any misuse or actions of the system.</p>
    <p>• Your data will be stored in Dinku Cards data base</p>
    <p>• All user data deleted by users from their profile will remain in Dinku Cards data base for some time. Such data, however, will not be available to anyone else.</p>
    <p>• Any violation of these terms and condition Gives Dinku Cards the unreserved right to cancel or suspend a user’s account with Dinku Cards</p>
</div>
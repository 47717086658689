import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from '@app/_services';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmPasswordValidator } from '@app/_helpers/custom.validators';
import { AlertpopupComponent } from '@app/alertpopup';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  isExtraSmall: Observable<BreakpointState> = this.breakpointObserver.observe(
    Breakpoints.XSmall
  );
  token:string = "";

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private SpinnerService: NgxSpinnerService,
    private userService: UserService,
    private readonly breakpointObserver: BreakpointObserver,
    public dialog: MatDialog,    
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {      
      var token = params.get('token');            
      if (token !== null) {        
        this.token = token;
      } 
    });

    this.resetPasswordForm = this.formBuilder.group({
      new_password: ['', [Validators.required, Validators.minLength(8)]],
      confirm_password: ['', Validators.required]} , { 
        validator: ConfirmPasswordValidator('new_password', 'confirm_password')
      });
  }

  get f() { return this.resetPasswordForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.resetPasswordForm.invalid) {      
      return;
    }
    
    if (this.token == "") {      
      this.error = "Invalid reset link";
      return;      
    }

    this.loading = true;
    var data = this.resetPasswordForm.value   
    data['token'] = this.token
    this.SpinnerService.show();
    this.userService.reset_password(data)
      .pipe(first())
      .subscribe(
        data => {          
          this.SpinnerService.hide();          
          this.alert("Password changed Successfully !!!. Please login");      
        },
        error => {          
          this.SpinnerService.hide();
          this.error = error;
          this.loading = false;
        });
  }

  alert(message:string):void{       
    const d = this.dialog.open(AlertpopupComponent, {
      panelClass: 'custom-dialog-container',      
      maxWidth: '95vw',
      data: {
        'message': message
      }
    });
    const smallDialogSubscription = this.isExtraSmall.subscribe(size => {
      if (size.matches) {        
        d.updateSize('100vw');
      } else {        
        d.updateSize('auto', '');
      }
    });
    d.afterClosed().subscribe(() => {
      smallDialogSubscription.unsubscribe();
      this.router.navigate(['login']);
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmPasswordValidator } from '@app/_helpers/custom.validators';
import { AlertpopupComponent } from '@app/alertpopup';
import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState
} from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatDialog } from '@angular/material/dialog';
import { User } from '@app/_models';
import { UserService } from '@app/_services';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';

  isExtraSmall: Observable<BreakpointState> = this.breakpointObserver.observe(
    Breakpoints.XSmall
  );
  isMobile = false;

  constructor(
    private formBuilder: FormBuilder,
    private SpinnerService: NgxSpinnerService,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private readonly breakpointObserver: BreakpointObserver,
    public dialog: MatDialog,
    private deviceService: DeviceDetectorService
    
  ) { }

  ngOnInit(): void {
    this.changePasswordForm = this.formBuilder.group({
      old_password: ['', [Validators.required]],
      new_password: ['', [Validators.required, Validators.minLength(8)]],
      confirm_password: ['', Validators.required]} , { 
        validator: ConfirmPasswordValidator('new_password', 'confirm_password')
      });
  }

  get f() { return this.changePasswordForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.changePasswordForm.invalid) {      
      return;
    }

    this.loading = true;
    var data = this.changePasswordForm.value    
    this.SpinnerService.show();
    this.userService.change_password(data)
      .pipe(first())
      .subscribe(
        data => {          
          this.SpinnerService.hide();
          this.alert("Saved Successfully!!!");          
        },
        error => {          
          this.SpinnerService.hide();
          this.error = error;
          this.loading = false;
        });
  }
  
  alert(message:string):void{       
    const d = this.dialog.open(AlertpopupComponent, {
      panelClass: 'custom-dialog-container',      
      maxWidth: '95vw',
      data: {
        'message': message
      }
    });
    const smallDialogSubscription = this.isExtraSmall.subscribe(size => {
      if (size.matches) {        
        d.updateSize('100vw');
      } else {        
        d.updateSize('auto', '');
      }
    });
    d.afterClosed().subscribe(() => {
      smallDialogSubscription.unsubscribe();
      this.router.navigate(['../home'], { relativeTo: this.route });
    });
  }
}

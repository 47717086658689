<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#458166" type="timer" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>

<div class="container2">
    <div class="row flex-lg-nowrap">
        <div class="col">
            <form class="form" [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()" novalidate="">
                <div class="card">
                    <div class="card-header bg-info text-center"
                    style="font-weight: bold; color: white; font-size:13px;height:35px">CHANGE PASSWORD</div>
                    <div class="card-body">
                        <div class="card-text">
                            <div class="row">
                                <div class="col">
                                    <div class="row">
                                        <div class="col">
                                            <div class="form-group">
                                                <label>Old Password</label>
                                                <input type="password" class="form-control"
                                                    formControlName="old_password"
                                                    [ngClass]="{ 'is-invalid': submitted && f.old_password.errors }"
                                                    placeholder="">
                                                <div *ngIf="submitted && f.old_password.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.old_password.errors.required">Old Password is required
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div class="form-group">
                                                <label>New Password</label>
                                                <input type="password" class="form-control"
                                                    formControlName="new_password"
                                                    [ngClass]="{ 'is-invalid': submitted && f.new_password.errors }"
                                                    placeholder="">
                                                <div *ngIf="submitted && f.new_password.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.new_password.errors.required">New password is required
                                                    </div>
                                                    <div *ngIf="f.new_password.errors.minlength">Minimum length is {{f.new_password.errors.minlength.requiredLength}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div class="form-group">
                                                <label>Confirm Password</label>
                                                <input type="password" class="form-control"
                                                    formControlName="confirm_password"
                                                    [ngClass]="{ 'is-invalid': submitted && f.confirm_password.errors }"
                                                    placeholder="">
                                                <div *ngIf="submitted && f.confirm_password.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.confirm_password.errors.required">Confirm Password is required
                                                    </div>
                                                    <div *ngIf="f.confirm_password.errors.confirmedValidator">Password
                                                        and Confirm Password must be match.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col d-flex justify-content-center">
                        <button class="btn btn-primary" style="width: 100%; font-weight: bolder;font-size:13px" type="submit">SUBMIT</button>
                    </div>
                </div>
                <div class="row">
                    <div class="col d-flex justify-content-center">
                        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="container">
    <br>
    <br>
    <div class="section-title">
    <h2 data-aos="fade-in">PRIVACY POLICY</h2>
    </div>
<h3>Dinku Cards gadgets & profile</h3>
<p>•	Dinku Cards  users data will be safe and secure</p>
<p>•	We don’t share customer details to any 3rd parties</p> 
<p>•	Data will be stored on Dinku Cards server and data base</p>
<p>•	Registration credential will be valuated </p>
<p>•	Cookies functionality is enabled to make your login easier </p>
<p>•	We keep your data as safe as possible.</p>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#458166" type="timer" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>

<div class="home" *ngIf="user" [ngClass]="classloginhome">
    <div class="card profile-card-3">
        <div class="card-content profile-data">
            <div class="profile-image-div">
                <img src="{{user.personal_details.profile_photo}}" alt="" class="profile-image" />
            </div>
            <h2>{{ user.personal_details.first_name | uppercase }}
                {{ user.personal_details.last_name | uppercase }}<small>{{ user.personal_details.role }}</small></h2>
            <div>
                <ul class="list-group" style="text-align: left;">
                    <li class="list-group-item" style="padding-top: 6px; min-height: 42px;
                    color: black; font-weight: bold; word-break: break-all;">
                        <i class="fa  fa-building picon" style="font-size:16px;"></i>
                        <span style="font-size: 15px;">{{ user.personal_details.company }}</span>
                    </li>
                    <li *ngIf="user.additional_phone_data?.length == 0" class="list-group-item" style="padding-top: 6px;
                     min-height: 42px; color: black; font-weight: bold;">
                        <i (click)="goToLink('tel:'+user.personal_details.phone, false)" class="fa fa-phone picon" style="font-size:16px;"></i>
                        <span style="margin-top: 5px;">{{ user.personal_details.phone}}</span>
                    </li>
                    <li *ngIf="user.additional_phone_data?.length > 0">
                        <mat-accordion *ngIf="user.additional_phone_data?.length > 0">
                            <mat-expansion-panel [expanded]="false" style="background-color:#C8E3EE;">
                                <mat-expansion-panel-header class="card-header" style="background-color: #C8E3EE;" [collapsedHeight]="'42px'" [expandedHeight]="'42px'">
                                    <mat-panel-title style="font-weight: bold; color: black;">
                                        <i (click)="goToLink('tel:'+user.personal_details.phone, false)" class="fa fa-phone picon" style="font-size:16px;"></i><span style="margin-top: 5px;">{{ user.personal_details.phone}}</span>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                    <li *ngFor="let item of user.additional_phone_data" class="list-group-item" style="background-color: #C8E3EE; height: 48px;padding-top: 5px;"><a style="color: black;" (click)="goToLink('tel:'+item.value, false)">
                            <i class="fa fa-phone picon" style="font-size:16px;"></i>
                            <span style="font-size: 15px;font-family: 'LATO', Arial, sans-serif;">{{ item.value }}</span></a>
                    </li>
                    </mat-expansion-panel>
                    </mat-accordion>
                    </li>
                    <li *ngIf="user.additional_email_data?.length == 0" class="list-group-item" style="padding-top: 6px;
                    min-height: 42px;color: black; font-weight: bold;">
                        <i (click)="goToLink('mailto:'+ user.personal_details.email, false)" class="fa fa-envelope picon" style="font-size:16px;"></i>
                        <span style="margin-top: 5px;">{{ user.personal_details.email}}</span>
                    </li>
                    <li *ngIf="user.additional_email_data?.length > 0">
                        <mat-accordion *ngIf="user.additional_email_data?.length > 0">
                            <mat-expansion-panel [expanded]="false" style="background-color:#C8E3EE;">
                                <mat-expansion-panel-header class="card-header" style="background-color: #C8E3EE;" [collapsedHeight]="'42px'" [expandedHeight]="'42px'">
                                    <mat-panel-title style="font-weight: bold; color: black;">
                                        <i (click)="goToLink('mailto:'+ user.personal_details.email, false)" class="fa fa-envelope picon" style="font-size:16px;"></i>
                                        <span style="margin-top: 5px;">{{ user.personal_details.email }}</span>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                    <li *ngFor="let item of user.additional_email_data" class="list-group-item" style="background-color:#C8E3EE; min-height: 48px;padding-top: 5px;"><a style="color: black;" (click)="goToLink('mailto:'+ item.value, false)">
                            <i class="fa fa-envelope picon" style="font-size:16px;"></i>
                            <span style="font-size: 15px; word-break: break-all;font-family: 'LATO', Arial, sans-serif;">{{ item.value }}</span></a>
                    </li>
                    </mat-expansion-panel>
                    </mat-accordion>
                    </li>
                    <li *ngIf="user.additional_website_data?.length == 0" class="list-group-item" style="padding-top: 6px;
                    min-height: 42px; color: black; font-weight: bold;">
                        <i (click)="goToLink(user.personal_details.website, true, true)" class="fa fa-globe picon" style="font-size:16px;"></i>
                        <span style="margin-top: 5px; word-break: break-all;">{{ user.personal_details.website}}</span>
                    </li>
                    <li *ngIf="user.additional_website_data?.length > 0">
                        <mat-accordion *ngIf="user.additional_website_data?.length > 0">
                            <mat-expansion-panel [expanded]="false" style="background-color:#C8E3EE;">
                                <mat-expansion-panel-header class="card-header" style="background-color:#C8E3EE;" [collapsedHeight]="'42px'" [expandedHeight]="'42px'">
                                    <mat-panel-title style="font-weight: bold; color: black;">
                                        <i (click)="goToLink(user.personal_details.website, true, true)" class="fa fa-globe picon" style="font-size:20px;"></i>
                                        <span style="font-size: 15px; margin-top: 5px; word-break: break-all;font-family: 'LATO', Arial, sans-serif;">{{ user.personal_details.website}}</span>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                    <li *ngFor="let item of user.additional_website_data" class="list-group-item" style="background-color:#C8E3EE;min-height: 48px;padding-top: 5px;"><a style="color: black;" (click)="goToLink(item.value, true, true)">
                            <i class="fa fa-globe picon" style="font-size:16px;"></i><span style="font-size: 15px;word-break: break-all;font-family: 'LATO', Arial, sans-serif;">{{ item.value }}</span></a>
                    </li>
                    </mat-expansion-panel>
                    </mat-accordion>
                    </li>
                    <li class="list-group-item" style="padding-top: 6px;
                    min-height: 42px;color: black; font-weight: bold;font-size: 15px;">
                        <a (click)="goToLink('https://www.google.com/maps/search/'+user.personal_details.address, true)">
                            <i class="fa fa-map-marker picon" style="font-size:16px;"></i>
                            <span style="font-size: 15px; word-break: break-all;">{{ user.personal_details.address }}</span></a>
                    </li>
                    <li>
                        <mat-accordion>
                            <mat-expansion-panel [expanded]="false" style="background-color:#C8E3EE;">
                                <mat-expansion-panel-header class="card-header" style="background-color:#C8E3EE;" [collapsedHeight]="'42px'" [expandedHeight]="'42px'">
                                    <mat-panel-title style="font-weight: bold; color: black;">
                                        <i class="fa fa-info picon" style="font-size:16px;"></i><span style="margin-top: 5px;">More Info</span>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div style="background-color: white;">
                                    <div *ngIf="user.additional_presentation_data.length>0">
                                        <h2 style="text-align: center; color: black;">Presentations</h2>
                    <li *ngFor="let item of user.additional_presentation_data" class="list-group-item" style="background-color: #C8E3EE;">
                        <a style="color: black;" (click)="goToLink(item.value, true, true)">
                            <h5>{{ item.description }}</h5>
                            <p>{{ item.value }}</p>
                        </a>
                    </li>
            </div>
            <div *ngIf="user.additional_presentation_data.length>0">
                <h2 style="text-align: center; color: black;">Videos</h2>
                <li *ngFor="let item of user.additional_video_data" class="list-group-item" style="background-color: #C8E3EE;">
                    <a style="color: black;" (click)="goToLink(item.value, true, true)">
                        <h5>{{ item.description }}</h5>
                        <p>{{ item.value }}</p>
                    </a>
                </li>
            </div>
        </div>
        </mat-expansion-panel>
        </mat-accordion>
        </li>
        </ul>
    </div>
    <div class="icon-block">
        <div class="connectwithme"><small><b>Connect with me:</b></small></div>
        <div style="width:100%">
            <a *ngFor="let item of user.social_media_data" (click)="goToLink('', true, false, true, item)">
                <button class="buttoncircle {{item.media}}" id="{{item.media}}" aria-label="social-media">
                    <i class="social-media fab fa-{{item.iconName}}" title="{{item.media}}"></i>
                </button></a>
        </div>

        <div class="w-100">
            <div class="btnshare">
                <button class="btn w-100 float-left text-center sharebutton" (click)="share()"><strong>SHARE</strong>
                </button>
            </div>

        </div>

    </div>



</div>
</div>

</div>
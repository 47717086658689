import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState
} from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';
import { UserService } from '@app/_services';
import { SharedService } from '@app/_services';
import { AlertpopupComponent } from '@app/alertpopup';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss']
})
export class DefaultComponent implements OnInit {
  emailForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  isExtraSmall: Observable<BreakpointState> = this.breakpointObserver.observe(
    Breakpoints.XSmall
  );
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private SpinnerService: NgxSpinnerService,
    private userService: UserService,
    private sharedService: SharedService,
    private readonly breakpointObserver: BreakpointObserver,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.emailForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      subject: ['', Validators.required],
      message: ['', Validators.required]
    });

    this.route.paramMap.subscribe(params => {
      this.SpinnerService.show();
      var serial = params.get('csr');
      console.log(serial);
      if (serial !== null) {
        this.userService.check_card(serial).subscribe(data => {
          this.SpinnerService.hide();
          if(data){
            this.sharedService.serial = serial;
            if(data['card_paired'] == true){
              this.router.navigate(['mycard']);
            }
            else{
              this.router.navigate(['register']);
            }
          }
        },error => {
          this.SpinnerService.hide();
          this.router.navigate(['']);
        });
      }
      else{
        this.SpinnerService.hide();
      }
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.emailForm.controls; }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.emailForm.invalid) {
      return;
    }

    this.loading = true;
    this.SpinnerService.show();
    var data = this.emailForm.value
    this.userService.send_mail(data).pipe().subscribe(data => {
          this.SpinnerService.hide();
          if(data['status'] == 'success'){
          this.alert("Thank you for contacting us. We will get back to you soon");
          this.emailForm.reset();
          this.submitted = false;
          }
          this.loading = false;
        },
        error => {
          this.SpinnerService.hide();
          this.error = error;
          this.loading = false;
        });
  }

  alert(message:string):void{
    const d = this.dialog.open(AlertpopupComponent, {
      panelClass: 'custom-dialog-container',
      maxWidth: '95vw',
      data: {
        'message': message
      }
    });
    const smallDialogSubscription = this.isExtraSmall.subscribe(size => {
      if (size.matches) {
        d.updateSize('100vw');
      } else {
        d.updateSize('auto', '');
      }
    });
    d.afterClosed().subscribe(() => {
      smallDialogSubscription.unsubscribe();
      this.router.navigate([''], { relativeTo: this.route });
    });
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { environment } from '@environments/environment';
import { User, Card } from '@app/_models';
import { SalesMail } from '@app/_models/salesmail';

@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<User[]>(`${environment.apiUrl}/auth/status`);
    }

    register(user: User) {
        return this.http.post(`${environment.apiUrl}/auth/register`, user);
    }

    update(fileItem: File, extraData?: object) {
        const formData: FormData = new FormData();

        if(fileItem){
            formData.append('photo', fileItem, fileItem.name);
        }
        formData.append('data', JSON.stringify(extraData))
        return this.http.post(`${environment.apiUrl}/auth/update`, formData);
    }

    check_card(serial:string){
        var data = {'url_serial': serial}
        return this.http.post(`${environment.apiUrl}/auth/card-status`, data, {
            headers : new HttpHeaders({'Content-Type': 'application/json'})
          });
    }

    get_all_cards(){
        return this.http.get<Card[]>(`${environment.apiUrl}/auth/cards`);
    }

    generate_cards(no_of_cards: number, company:string) {
        var data = {'no_of_cards': no_of_cards, 'company': company}
        return this.http.post(`${environment.apiUrl}/auth/card-generate`, data);
    }

    send_mail(salesmail: SalesMail) {
        return this.http.post(`${environment.apiUrl}/auth/send-sales-mail`, salesmail);
    }

    confirm_account(token:string){
        var data = {'token': token}
        return this.http.post(`${environment.apiUrl}/auth/activate`, data, {
            headers : new HttpHeaders({'Content-Type': 'application/json'})
          });
    }

    change_password(extraData?: object) {
        const formData: FormData = new FormData();
        formData.append('data', JSON.stringify(extraData))
        return this.http.post(`${environment.apiUrl}/auth/change-password`, formData);
    }

    forgot_password(extraData?: object) {
        const formData: FormData = new FormData();
        formData.append('data', JSON.stringify(extraData))
        return this.http.post(`${environment.apiUrl}/auth/forgot-password`, formData);
    }

    reset_password(extraData?: object) {
        const formData: FormData = new FormData();
        formData.append('data', JSON.stringify(extraData))
        return this.http.post(`${environment.apiUrl}/auth/reset-password`, formData);
    }

    update_additional_data(extraData?: object) {
        const formData: FormData = new FormData();
        formData.append('data', JSON.stringify(extraData))
        return this.http.post(`${environment.apiUrl}/auth/additional-data`, formData);
    }

    delete_additional_data(data_id:string) {
        var data = {'id': data_id}
        return this.http.post(`${environment.apiUrl}/auth/delete-additional-data`, data);
    }

    delete_card(data_id:string) {
        var data = {'id': data_id}
        return this.http.post(`${environment.apiUrl}/auth/delete-card`, data);
    }

    user_profile_update(serial:string, fileItem: File, extraData?: object) {
        const formData: FormData = new FormData();

        if(fileItem){
            formData.append('photo', fileItem, fileItem.name);
        }
        extraData['url_serial'] = serial;
        var data = JSON.stringify(extraData);
        formData.append('data', data)
        return this.http.post(`${environment.apiUrl}/auth/user-profile-update`, formData);
    }

    export_data(company: string, status:string, company_name:string,){
        let params = new HttpParams().set("company", company).set("status", status);
        return this.http.get(`${environment.apiUrl}/auth/export-data`, {params:params, observe:"response", responseType: "blob"});
    }
}
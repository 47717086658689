import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { UserService } from '@app/_services';
import { ConfirmPasswordValidator } from '@app/_helpers/custom.validators';
import { SharedService } from '@app/_services';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertpopupComponent } from '@app/alertpopup';
import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
} from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  registrationForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';

  isExtraSmall: Observable<BreakpointState> = this.breakpointObserver.observe(
    Breakpoints.XSmall
  );

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private SpinnerService: NgxSpinnerService,
    private userService: UserService,
    private sharedService: SharedService,
    private readonly breakpointObserver: BreakpointObserver,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.SpinnerService.show();
    var serial = this.sharedService.serial;
    if (serial) {
      this.SpinnerService.hide();
      this.registrationForm = this.formBuilder.group(
        {
          first_name: ['', Validators.required],
          last_name: ['', Validators.required],
          email: ['', [Validators.required, Validators.email]],
          password: ['', [Validators.required, Validators.minLength(8)]],
          confirm_password: ['', Validators.required],
        },
        {
          validator: ConfirmPasswordValidator('password', 'confirm_password'),
        }
      );
    } else {
      this.SpinnerService.hide();
      this.router.navigate(['']);
    }
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.registrationForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.registrationForm.invalid) {
      return;
    }

    this.loading = true;
    var data = this.registrationForm.value;
    data['url_serial'] = this.sharedService.serial;
    this.SpinnerService.show();
    this.userService
      .register(data)
      .pipe(first())
      .subscribe(
        (data) => {
          this.SpinnerService.hide();
          // this.router.navigate(['../login'], { relativeTo: this.route });
          this.alert('Registered Successfully.');
        },
        (error) => {
          this.SpinnerService.hide();
          this.error = error;
          this.loading = false;
        }
      );
  }

  alert(message: string): void {
    const d = this.dialog.open(AlertpopupComponent, {
      panelClass: 'custom-dialog-container',
      maxWidth: '95vw',
      data: {
        message: message,
      },
    });
    const smallDialogSubscription = this.isExtraSmall.subscribe((size) => {
      if (size.matches) {
        d.updateSize('100vw');
      } else {
        d.updateSize('auto', '');
      }
    });
    d.afterClosed().subscribe(() => {
      smallDialogSubscription.unsubscribe();
      this.router.navigate(['../login'], { relativeTo: this.route });
    });
  }
}

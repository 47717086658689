<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#458166" type="timer" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>
<div *ngIf="data_loaded">
    <div class="col-md-4">
    <form [formGroup]="adminForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
            <label for="no_of_cards">No of Cards</label>
            <input type="number" formControlName="no_of_cards" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.no_of_cards.errors }" />
            <div *ngIf="submitted && f.no_of_cards.errors" class="invalid-feedback">
                <div *ngIf="f.no_of_cards.errors.required">No of Cards is required</div>
                <div *ngIf="f.no_of_cards.errors.min">Minimum is 1</div>
                <div *ngIf="f.no_of_cards.errors.max">Maximum is 100</div>
            </div>
        </div>
        <div class="form-group">
            <label for="no_of_cards">Cards for(Company/Group)</label>
            <input type="text" formControlName="company" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.company.errors }" />
            <div *ngIf="submitted && f.company.errors" class="invalid-feedback">
                <div *ngIf="f.company.errors.required">This field is required</div>
            </div>
        </div>
        <button [disabled]="loading" class="btn btn-primary">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            Generate Cards
        </button>
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
        <div *ngIf="success" class="alert alert-success mt-3 mb-0">{{success}}</div>
    </form>
    </div>
    <div style="text-align: right; margin-top: 2%;">
    <button class="btn btn-success" (click)="exportAsXLSX()" ><fa-icon class="normal" [icon]="['fas', 'file-export']" size="1x"></fa-icon>Export</button>
    </div>
    <h3>Cards Master</h3>
    <div class="example-header">
        <div class="form-group">
          <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
        </div>
    </div>
<mat-table [dataSource]="cards" class="table table-stripped">
    <ng-container  matColumnDef="serial">
        <mat-header-cell *matHeaderCellDef style="color: white; font-weight: bold; font-size: large; background: black;">Serial</mat-header-cell>
        <mat-cell *matCellDef="let card"><a href="{{card_url}}{{card.url_serial}}" target="_blank">{{ card.serial }}</a></mat-cell>
    </ng-container>    
    <ng-container  matColumnDef="qr_code">
        <mat-header-cell *matHeaderCellDef style="color: white; font-weight: bold; font-size: large; background: black;">QR Code</mat-header-cell>
        <mat-cell *matCellDef="let card"><a (click)="open_qr(card.url_serial)">View</a></mat-cell>
    </ng-container>              
    <ng-container  matColumnDef="company">
        <mat-header-cell *matHeaderCellDef style="color: white; font-weight: bold; font-size: large; background: black;">Company/Group</mat-header-cell>
        <mat-cell *matCellDef="let card">{{ card.company }}</mat-cell>
    </ng-container>  
    <ng-container  matColumnDef="paired_on">
        <mat-header-cell *matHeaderCellDef style="color: white; font-weight: bold; font-size: large; background: black;">Paired On</mat-header-cell>
        <mat-cell *matCellDef="let card">{{ card.paired_on }}</mat-cell>
    </ng-container>          
    <ng-container  matColumnDef="user">
        <mat-header-cell *matHeaderCellDef style="color: white; font-weight: bold; font-size: large; background: black;">User</mat-header-cell>
        <mat-cell *matCellDef="let card">{{ card.user }}</mat-cell>
    </ng-container>          
    <ng-container  matColumnDef="id">
        <mat-header-cell *matHeaderCellDef style="color: white; font-weight: bold; font-size: large; background: black;">Action</mat-header-cell>
        <mat-cell *matCellDef="let card"><td><i (click)="delete(card.id)" class="fa fa-trash" style="color: red;"></i></td></mat-cell>
    </ng-container>    
    <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: tableColumns"></mat-row>    
</mat-table>
</div>
<mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 15]" showFirstLastButtons></mat-paginator>
<div class="body">

    <div class="col-md-4 offset-md-4 mt-4 login">
        <div class="card">
            <div class="card-body">
                <h4>DINKU CARDS LOGIN</h4>
                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <label for="username">Username</label>
                        <input type="text" placeholder="USERNAME" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                        <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                            <div *ngIf="f.username.errors.required">USERNAME IS REQUIRED</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="password">Password</label>
                        <input type="password" placeholder="PASSWORD" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                            <div *ngIf="f.password.errors.required">PASSWORD IS REQUIRED</div>
                        </div>
                    </div>
                    <div style="text-align: left;margin-top: 15px; margin-bottom: 30px; font-size: 12px; ">
                        <input style="width: 3%;" type="checkbox" formControlName="terms" [ngClass]="{ 'is-invalid': submitted && f.terms.errors }" />
                        <a href="/terms" target="_blank" style="margin-left:9px;font-weight: 600;font-size: 10px;">TERMS AND CONDITIONS</a>
                        <div *ngIf="submitted && f.terms.errors" class="invalid-feedback">
                            <div *ngIf="f.terms.errors.required">PLEASE ACCEPT TERMS</div>
                            <div *ngIf="f.terms.errors.confirmedValidator">PLEASE ACCEPT TERMS</div>
                        </div>
                    </div>
                    <button [disabled]="loading" class="btn">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        LOGIN
                    </button>
                    <div style="text-align: center;margin-top: 25px;font-size: 10px;font-weight: bold;">
                        <a href="/forgot-password" target="_blank">FORGOT PASSWORD</a>
                    </div>
                    <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
                </form>
            </div>
        </div>
    </div>
</div>
<div>
  <h6 style="text-align: center;">Share Dinku Cards or scan it</h6>
  <div class="icon-block">
    <a (click)="goToLink('mailto:?subject=Click below link for my contact details&body='+myAngularxQrCode, false)">
      <button class="buttoncircle envolope">
        <i class="fas fa-envelope" style="color:white;" title="mail"></i>
      </button></a>
    <a (click)="goToLink('sms:&body='+ myAngularxQrCode, false)"><button class="buttoncircle comment">
        <i class="fas fa-comment" style="color:white;" title="message"></i>
      </button></a>
    <a (click)="goToLink('https://api.whatsapp.com/send?phone=&text='+ myAngularxQrCode, true)"><button class="buttoncircle whatsapp">
        <i class="fab fa-whatsapp" style="color:white;" title="whatsapp"></i>
      </button></a>
    <a (click)="goToLink('https://www.facebook.com/sharer.php?u='+myAngularxQrCode, true)"><button class="buttoncircle facebook">
        <i class="fab fa-facebook-f" style="color:white;" title="facebook"></i>
      </button></a>
    <a (click)="goToLink('https://twitter.com/intent/tweet?text=Dinku-Cards Contact&url='+myAngularxQrCode, true)"><button class="buttoncircle twitter">
        <i class="fab fa-twitter" style="color:white;" title="twitter"></i>
      </button></a>
    <a (click)="goToLink('https://www.linkedin.com/shareArticle?mini=true&url='+myAngularxQrCode+'&title=Dinku-Cards Contact&summary=Dinku-Cards Contact', true)"><button class="buttoncircle linkedin">
        <i class="fab fa-linkedin" style="color:white;" title="linkedin"></i>
      </button></a>
  </div>
  <div style="margin-top: 5%; text-align: center;">
    <qrcode [qrdata]="myAngularxQrCode" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
  </div>
</div>
import { Component, IterableDiffers, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { NgxSpinnerService } from "ngx-spinner";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState
} from '@angular/cdk/layout';
import { IconPrefix, IconName } from '@fortawesome/fontawesome-svg-core';

import { User } from '@app/_models';
import { UserService } from '@app/_services';
import { SocialShareDialogComponent } from '@app/social-share-dialog'
import { Observable } from 'rxjs';
import { isNgTemplate } from '@angular/compiler';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  loading = false;
  user: User;
  card_url: string;
  classlogin = 'nologinclass';
  classloginhome = 'nologinclasshome';
  iconName: IconName = 'facebook-f';
  iconPrefix: IconPrefix = 'fab'
  isExtraSmall: Observable<BreakpointState> = this.breakpointObserver.observe(
    Breakpoints.XSmall
  );

  constructor(
    private userService: UserService,
    private SpinnerService: NgxSpinnerService,
    public dialog: MatDialog,
    private readonly breakpointObserver: BreakpointObserver
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.SpinnerService.show();
    this.userService.getAll().pipe(first()).subscribe(users => {
      this.SpinnerService.hide();
      this.card_url = users['card_url']
      this.user = users['data'];
    },
    error => {
      this.loading = false;
      this.SpinnerService.hide();
    });
  }

  share():void{
    const d = this.dialog.open(SocialShareDialogComponent, {
      panelClass: 'custom-dialog-container',
      maxWidth: '95vw',
      data: {
        'url_serial': this.card_url + this.user.personal_details.url_serial
      }
    });
    const smallDialogSubscription = this.isExtraSmall.subscribe(size => {
      if (size.matches) {
        d.updateSize('100vw');
      } else {
        d.updateSize('auto', '');
      }
    });
    d.afterClosed().subscribe(() => {
      smallDialogSubscription.unsubscribe();
    });
  }

  goToLink(url: string, _blank: boolean, website:boolean=false, social=false, item:any=null){
    if(!social){
    if(website){
      if(url.startsWith('http') || url.startsWith('https'))
      {
        window.open(url, "_blank");
      }
      else{
        window.open('http://'+url, "_blank");
      }
    }
    else if(_blank){
    window.open(url, "_blank");
    }
    else{
      window.location.href = url;
    }
  }
  else{
    if(item.media=='youtube' || item.media=='linkedin'){
      url = item.profile_id;
    }
    else{
      url = item.profile_url + item.profile_id;
    }
    if(_blank){
      window.open(url, "_blank");
      }
      else{
        window.location.href = url;
      }
  }
  }
}
